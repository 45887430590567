export const artCons: any = {
  hcpMainMenu: "Main Navigational Menu HCP GCSO - Retina Global",
  patMainMenu: "Main Navigational Menu Consumer GCSO - Global",
  hcpFooterMenu: "Footer Navigation Menu HCP GCSO - Retina Global",
  patFooterMenu: "Footer Navigation Menu Consumer GCSO - Global",
  carousel: "",
  banner: "",
  hcpFooter: "Retina Global Footer HCP - US - Global",
  patFooter: "Retina Global Footer Consumer - US - Global",
  exitPopup: "Retina Canada Exit Popup New - Global",
  hcpSwitcher: "HCP Switcher Modal Pop Up - Global",
  hcpTopNav: "Top Navigational Menu HCP US - Global",
  patTopNav: "Top Navigational Menu Consumer US - Global",
  hcpSiteLogo: "Site logos - Global",
  patSiteLogo: "Site logos - Global",
  hcpSitemapLabel: "Retina HCP Sitemap - Full HTML - Global",
  patSitemapLabel: "Retina DTC Sitemap - Full HTML - Global",
  privacyPara: "Retina Canada Privacy Policy - Global",
  bannerSitemap: "Banner - HTML - Sitemap - US - Global",
  bannerPrivacy: "",
  hcpSMRedirectLangUrl: {
    "redirectUrl": {
      "en": "/hcp/sitemap",
      "es": "/es/hcp/sitemap"
    }
  },
  patSMRedirectLangUrl: {
    "redirectUrl": {
      "en": "/sitemap",
      "es": "/es/sitemap"
    }
  },
  hcpPPRedirectLangUrl: {
    "redirectUrl": {
      "en": "/hcp/privacy-policy",
      "es": "/es/hcp/privacy-policy"
    }
  },
  patPPRedirectLangUrl: {
    "redirectUrl": {
      "en": "/privacy-policy",
      "es": "/es/privacy-policy"
    }
  },
  backToTopGTMPP: "Back to top - privacy policy",
  backToTopGTMSM: "Back to top - site map",
  footerClassName: "common-footer"
}