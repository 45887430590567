import React from 'react'
import { graphql, withPrefix } from 'gatsby'
import { Layout, PageWrapper, PrivacyPolicyWrapper, HTMLRenderer } from "@retina-packages/retina-theme-bootstrap"
import { PageQueryResult } from '@retina-packages/retina-theme-bootstrap/packages/types/CommonTypes';
import ArticleContent from '@retina-packages/retina-theme-bootstrap/packages/containers/ArticleContainer';
import MetaTagContainer from '@retina-packages/retina-theme-bootstrap/packages/containers/MetaTagContainer';
import { exitPopDataType } from '@retina-packages/retina-theme-bootstrap/packages/types/ExitPopUpType';
import { deriveSiteLogo, deriveSiteMenus, fullHTMLDataBuilder } from '../../../utils';
import retinaConfig from '../../../utils/retinaConfigs';
import { artCons } from './constants';
import reusableDataBuilders from '@retina-packages/retina-theme-bootstrap/packages/helpers/reusableDataBuilders';
import { MobileBanner } from '@retina-packages/retina-theme-bootstrap/packages/components/styles/styles';
import { compareUrls } from '@retina-packages/retina-theme-bootstrap/packages/helpers'
import { PrivacyPolicyWrapperContainer, SitemapStyle } from "./styles"

export interface Props {
  data?: any;
  location?: any;
  title?: string;
  path?: any;
  pageContext?: any;
  isDefault?: any;
  locale?: any;
}

/** Rendering template for Primary block
 *
 * @param props props
 * @returns
 */
const PrimaryTemplate = (props: Props) => {

  const pgProp: PageQueryResult = props.data
  const blocks = pgProp.nodePage.relationships.field_paragraphs_gcso
  const pgContextsART = props.pageContext
  const htmlStaticFilesART = {
    nonSVGImages: pgContextsART.nonSVGImages,
    svgMediaImages: pgContextsART.svgMediaImages,
    allMediaDocument: pgContextsART.documents
  }
  const audience = pgProp.nodePage.relationships.field_general_tags_gcso[0].name

  const privacyPageUrl = pgContextsART.urlMap["/privacy-policy"].pathSlug
  const hcpPrivacyPageUrl = pgContextsART.urlMap["/hcp/privacy-policy"].pathSlug
  const sitemapPageUrl = pgContextsART.urlMap["/sitemap"].pathSlug
  const hcpSitemapPageUrl = pgContextsART.urlMap["/hcp/sitemap"].pathSlug
  const isdtcPrivacyPage = compareUrls(decodeURI(props.location.pathname), withPrefix(privacyPageUrl))
  const ishcpPrivacyPage = compareUrls(decodeURI(props.location.pathname), withPrefix(hcpPrivacyPageUrl))
  let languageConfig: any = Object.assign({}, retinaConfig.langConfig)

  let topMenuLabel = ""
  let foorMenuLabel = ""
  let footerTitle = ""
  let gtmBackToTopLabel = ""
  let topNavTitle = ""
  let logoLabel = ""
  let siteMapLevel = ""
  let bannerTitle = ""
  let pagewrapper = ""
  let clsNameForprivacypolicypatientPage = ""
  let clsNameForprivacypolicyhcp = ""
  let clsNameForprivacypolicyhcpPage = ""
  let hcpSitemapPage = ""
  let layoutAttrs;
  let addHcpPrefix = false;

  if (audience === "Professional") {
    addHcpPrefix = retinaConfig.addHcpPrefix;
    topMenuLabel = artCons.hcpMainMenu
    foorMenuLabel = artCons.hcpFooterMenu
    footerTitle = artCons.hcpFooter
    topNavTitle = artCons.hcpTopNav
    logoLabel = artCons.hcpSiteLogo
    siteMapLevel = artCons.hcpSitemapLabel
    layoutAttrs = { ... { ...retinaConfig.layoutAttrs.hcpAttrs, ...retinaConfig.layoutAttrs.commonAttrs } }
    languageConfig = null
    if (ishcpPrivacyPage) {
      bannerTitle = artCons.bannerPrivacy
      pagewrapper = "privacy-site"
      gtmBackToTopLabel = artCons.backToTopGTMPP
      clsNameForprivacypolicyhcp = "privacypolicyhcp"
      clsNameForprivacypolicyhcpPage = "privacypolicyhcppagewrap"
    }
    else {
      bannerTitle = artCons.bannerSitemap
      pagewrapper = "sitemap"
      gtmBackToTopLabel = artCons.backToTopGTMSM
      hcpSitemapPage = "hcpsitemappage"
    }
  }
  else {
    topMenuLabel = artCons.patMainMenu
    foorMenuLabel = artCons.patFooterMenu
    footerTitle = artCons.patFooter
    topNavTitle = artCons.patTopNav
    logoLabel = artCons.patSiteLogo
    siteMapLevel = artCons.patSitemapLabel
    layoutAttrs = { ... { ...retinaConfig.layoutAttrs.dtcAttrs, ...retinaConfig.layoutAttrs.commonAttrs } }
    if (isdtcPrivacyPage) {
      bannerTitle = artCons.bannerPrivacy
      pagewrapper = "privacy-site"
      gtmBackToTopLabel = artCons.backToTopGTMPP
      clsNameForprivacypolicypatientPage = "privacypolicypatientpagewrap"
      languageConfig = Object.assign({}, { ...languageConfig }, { ...artCons.patPPRedirectLangUrl })
    }
    else {
      bannerTitle = artCons.bannerSitemap
      pagewrapper = "sitemap"
      gtmBackToTopLabel = artCons.backToTopGTMSM
      languageConfig = Object.assign({}, { ...languageConfig }, { ...artCons.patSMRedirectLangUrl })
    }
  }

  const { mainMenu, footerMenu } = deriveSiteMenus({ pgProp, siteMainMenu: topMenuLabel, siteFooterMenu: foorMenuLabel, addHCPPrefix: addHcpPrefix });

  const carouselTitle = artCons.carousel
  const exitPopupTitle = artCons.exitPopup
  const hcpSwitcherTitle = artCons.hcpSwitcher
  const {
    footerText,
    exitData,
    hcpSwitcher,
    topNavs
  }: {
    footerText: string;
    exitData: exitPopDataType;
    hcpSwitcher: exitPopDataType;
    topNavs: string;
  } = reusableDataBuilders({ blocks, bannerTitle: "", carouselTitle, audience, footerTitle, exitPopupTitle, hcpSwitcherTitle, topNavTitle });

  const bannerHTML = fullHTMLDataBuilder({ blocks: blocks, title: bannerTitle });
  const dataSetArt = fullHTMLDataBuilder({ blocks, title: artCons.privacyPara });
  const siteMapFullHTMLArt = fullHTMLDataBuilder({ blocks, title: siteMapLevel });
  const siteLogos: any = deriveSiteLogo({ blocks, title: logoLabel })
  const currentLocation = props?.location?.pathname ? decodeURI(props.location.pathname) : "";

  return (
    <SitemapStyle className={`site-map-style ${hcpSitemapPage}`}>
      <PrivacyPolicyWrapperContainer>
        <PrivacyPolicyWrapper className={`${clsNameForprivacypolicyhcp} ${clsNameForprivacypolicyhcpPage} ${clsNameForprivacypolicypatientPage}`}>
          <div>
            {props?.pageContext !== null && props?.pageContext.metaInfo !== null && (
              <MetaTagContainer metaData={props?.pageContext?.metaInfo} />
            )}
            <Layout
              title={"siteTitle"}
              location={props.location}
              data={mainMenu}
              mdata={footerMenu}
              footerData={footerText}
              audience={audience}
              exitPopData={exitData}
              hcplinks={hcpSwitcher}
              topNavigation={topNavs}
              siteLogos={siteLogos}
              gtmBackToTopLabel={gtmBackToTopLabel}
              modalCloseButtonLabel={retinaConfig.modalCloseButtonLabel}
              modalCloseButtonGTMLabel={retinaConfig.modalCloseButtonGTMLabel}
              staticMediaFiles={htmlStaticFilesART}
              backToTopContent={retinaConfig.backToTopContent}
              hcpValidate={retinaConfig.hcpValidate}
              hcpHomeUrl={retinaConfig.hcpHomeUrl}
              hcpPrefix={retinaConfig.hcpPrefix}
              siteHomeUrl={retinaConfig.siteHomeUrl}
              preIndexUrl={retinaConfig.preIndexUrl}
              footerClassName={artCons.footerClassName}
              cpraFooter={true}
              cpraFooterEnv={process.env.CPRA_FOOTER_ENV}
              cpraOT={true}
              cpraOTEnv={process.env.OT_ENV}
              cpraOTuuid={process.env.OT_UUID}
              languageConfig={languageConfig}
              {...layoutAttrs}
            >
              <PageWrapper className={`pagewrapper ${pagewrapper} `}>
                <MobileBanner className="mobile-banner clinical-trials-banner">
                  <HTMLRenderer data={htmlStaticFilesART} html={bannerHTML} tagName='section' className='internal-banner o-column--full' simpleImage={true} />
                </MobileBanner>
                <ArticleContent
                  data={dataSetArt}
                  hcpHomeUrl={retinaConfig.hcpHomeUrl}
                  path={currentLocation}
                  mainMenu={mainMenu}
                  footerMenu={footerMenu}
                  audience={audience}
                  location={props.location}
                  hcplinks={hcpSwitcher}
                  topNavigation={topNavs}
                  siteMapContent={siteMapFullHTMLArt}
                  privacyPageUrl={privacyPageUrl}
                  hcpPrivacyPageUrl={hcpPrivacyPageUrl}
                  sitemapPageUrl={sitemapPageUrl}
                  hcpSitemapPageUrl={hcpSitemapPageUrl} />
              </PageWrapper>
            </Layout>
          </div>
        </PrivacyPolicyWrapper>
      </PrivacyPolicyWrapperContainer>
    </SitemapStyle>
  )
}

export default PrimaryTemplate

// Page query in GraphQL
export const pageQuery = graphql`
  query($id: Int!) {
    nodePage(drupal_internal__nid: {eq: $id}) {
      relationships {
        ... ArticlePageQuery
      }
    }
  }
`