import styled from 'styled-components'
import { device } from '@retina-packages/retina-theme-bootstrap/packages/css/device'

export const SitemapStyle = styled.div`
	&.site-map-style {
		&.hcpsitemappage {
			.sitemap {
				.clinical-trials-banner {
					.internal-banner {
						height : 190px;
						@media ${device.laptop} {
							height : 217px;
						}
						.internal-banner__image {
							.desktop-image {
								img {
									height : 186px;
								}
							}
						}
						.internal-banner__large-title {
							padding: 2rem 5rem;
							line-height: 6.4rem;
							@media ${device.laptop} {
								padding: 2rem 0.1rem 2rem 5rem;
							}
						}
						&:after {
							@media ${device.laptopMedium} {
								top: calc(100% - 40px);
							}
						}
					}
				}
				.site-map {
					.sitemap-block {
						ul {
							@media ${device.laptopMedium} {
								padding: 60px 43px 34px;
							}
							li {
								a {
									padding: 8px 0px;
									line-height: 2.6rem;
									@media ${device.laptop} {
										font-size: 2.2rem;
										line-height: 3.2rem;
									}
									&.o-margin-top {
										margin-top: 48px;
										@media ${device.laptop} {
											margin-top: 64px;
										}
									}
								}
								&:nth-child(7) {
									.sitemap-patients-link {
										margin-top: 48px;
										@media ${device.laptop} {
											margin-top: 64px;
										}
									}
								}
							}
						}
					}
				}
			}
			.pagewrapper {
				&.sitemap {
					&:before {
						top: 56px;
						height: 151px !important;
					}
				}
			}
		}

		.sitemap {
			.clinical-trials-banner {
				@media ${device.laptopMedium} {
					height: 150px;
				}
				.internal-banner {
					height: 150px;

					@media ${device.desktopsignup} {
						height: 100%;
					}

					&:after {
						height: 32px;
					}

					.internal-banner__image {
						.desktop-image {
							display: none;

							@media ${device.ipadLandscapemin} {
								display: block;
							}

							img {
								width: 100%;
								height: 180px;
							}
						}

						.mobile-image {
							display: block !important;

							@media ${device.ipadLandscapemin} {
								display: none !important;
							}

							img {
								height: 150px;
								width: 100%;
							}
						}
					}

					.internal-banner__contant {
						.o-container {
							.internal-banner__large-title {
								@media ${device.laptop} {
									max-width: 100%;
									padding: 58px 1px 25px 50px;
								}
							}
						}
					}
				}
			}

			.site-map {
				.sitemap-block {
					padding: 0;

					@media ${device.laptop} {
						padding-left: 35px;
					}

					@media ${device.laptop} {
						margin: 0px 0px 0px 140px;
    				padding: 90px 0px 110px;
					}

					ul {
						padding: 28px 43px 34px;
						margin: 0;

						@media ${device.ipadLandscapemin} {
							padding: 28px 0 34px;
						}

						@media ${device.laptop} {
							padding: 0;
							margin-bottom: 10px;
						}

						li {
							a {
								text-decoration: underline;
								@media ${device.desktopsignup} {
									font-size: 2.2rem;
									font-family: "Gotham", Arial, Helvetica, sans-serif;
									line-height: normal;
									text-decoration-thickness: 0.1em;
									line-height: 3.2rem;
								}
							}
						}
					}
				}
			}
		}

		.footer {
			.footercontainer {
				footer {
					padding: 0 10px;

					@media ${device.ipadLandscapemin} {
						padding: 0 3rem;
					}

					@media ${device.desktopsignup} {
						padding: 0 2rem;
					}

					>.row {
						padding: 0;

						.show-hide {
							@media ${device.ipadLandscapemin} {
								margin-bottom: 70px;
							}
						}
					}
				}
			}

			.o-backtotop-btn {
				&.gtm-link-internal {
					top: -100px;

					@media ${device.ipadLandscapemin} {
						top: -135px;
					}
				}
			}
		}
	}
`;

export const PrivacyPolicyWrapperContainer = styled.div`
	.privacypolicyhcp {
		.internal-banner {
			.internal-banner__contant {

				@media ${device.laptopMedium} {
					top: 20%;
				}
				@media ${device.mobileMedium} {
					top: 20%;
				}
				.o-container {
					.o-header {

						@media ${device.desktopsignup} {
							padding-left: 5% !important;
							font-family: 'Gotham-Bold', Arial, Helvetica, sans-serif;
						}
						@media ${device.mobileMedium} {
							padding: 0 0 !important;
							font-size: 3rem;
						}
					}
				}
			}
			.internal-banner__image {
				.desktop-image,.media--image,img {
					height: 100%;

					img {
						width: 100%;
					}
				}
			}
		}
	}
	.privacypolicyhcppagewrap {

		&:before {
			@media ${device.desktopsignup} {
				height: 0;
			}
		}
		.internal-banner {
			@media ${device.desktopsignup} {
				height: 341px;
			}
			@media ${device.laptopMedium} {
				height: 262px;
			}
		}
		.privacypolicycontent {
			@media ${device.tabletMax} {
				margin-top: 0;
			}
			@media ${device.ipadLandscapemin} and ${device.midDesktopMax} {
				padding: 0px 50px 40px;
			}
			.o-paragraph {
				@media ${device.desktopsignup} {
					margin-bottom: 3rem;
				}
				@media ${device.laptopMedium} {
					line-height: 2.2rem;
				}

				strong {
					@media ${device.desktopsignup} {
						font-family: 'Gotham-Bold', Arial, Helvetica, sans-serif;
					}
				}

				&:nth-child(5) {
					@media ${device.desktopsignup} {
						margin-bottom: 0;
					}
				}
				&:nth-child(6) {
					@media ${device.desktopsignup} {
						margin-bottom: 2.2rem;
					}
				}
				&:nth-child(7) {
					@media ${device.desktopsignup} {
						margin-bottom: 1.5rem;
					}
					strong {
						@media ${device.desktopsignup} {
							font-family: 'Gotham-Book' !important;
							font-weight: normal;
						}
					}
				}

				.gtm-cta {
					@media ${device.laptopMedium} {
						word-break: break-all;
						white-space: break-spaces;
					}
				}
			}

			ul {
				&:nth-child(1) {
					.o-paragraph {
						@media ${device.desktopsignup} {
							font-family: 'Gotham', Arial, Helvetica, sans-serif;
							font-weight: 500;
						}

						strong {
							@media ${device.desktopsignup} {
								font-family: 'Gotham', Arial, Helvetica, sans-serif;
								font-weight: 500;
							}
						}
					}
				}
				.para-medium {
					font-family: 'Gotham', Arial, Helvetica, sans-serif;
					font-weight: 500;
				}

			}
			.o-container {
				@media ${device.miniDesktop}{
					padding: 0px 3rem;
				}
				@media ${device.tabletMax} {
					padding: 0px 2.7rem !important;
				}
				@media ${device.laptopMedium} {
					max-width: 1188px !important;
				}
			}
			.intro-section {
				@media ${device.desktopsignup} {
					margin: 6.8rem auto 2rem;
				}
				@media ${device.tabletMax} {
					margin: 30px 0px 24px;
				}
				.o-header--h2 {
					font-weight: 700;
					font-family: 'Gotham-Bold', Arial, Helvetica, sans-serif;

					@media ${device.laptopMedium} {
						font-weight: 600;
						margin: 0 0 15px;
					}
				}
			}
			.o-common-title {
				@media ${device.desktopsignup} {
					font-family: 'Gotham-Bold', Arial, Helvetica, sans-serif;
				}

				@media ${device.desktopsignup} {
					margin-top: 0;
					margin-bottom: 1.5rem;
				}

				@media ${device.laptopMedium} {
					margin-top: 3rem;
					margin-bottom: 2.4rem;
				}
				@media ${device.mobileMedium} {
					font-size: 2.7rem;
					line-height: 3.8rem;
				}
			}
		}

		.footer {
			.o-backtotop-btn {
				@media ${device.laptopMedium} {
					top: -100px;
				}
			}
			.footercontainer {
				@media ${device.tabletMax} {
					margin: 80px auto 0;
				}
				footer {
					@media ${device.laptopMedium} {
						padding: 0 2rem;
					}
					@media ${device.laptopPros} {
						padding: 0 10px !important;
					}

					> .row {
						@media ${device.laptopMedium} {
							padding-top: 0;
						}
						@media ${device.ipadLandscapemin} {
							padding-top: 0;
						}
					}
				}
			}
		}
	}

	.privacypolicypatientpagewrap {
		.internal-banner__image {
			.desktop-image,.media--image,img {
				height: 100%;

				img {
					width: 100%;
				}
			}
		}
		.internal-banner  {
			.internal-banner__contant {
				.o-container {
					.o-header {
						@media ${device.desktopsignup} {
							padding-left: 5%;
							font-family: 'Gotham-Bold', Arial, Helvetica, sans-serif;
						}
						@media ${device.mobileMedium} {
							padding: 0 !important;
						}
					}
				}
				.internal-banner__large-title {
					@media ${device.ipadLandscapemin} {
						line-height: 5.9rem;
					}
				}
			}
		}
		.intro-section {
			@media ${device.desktopsignup} {
				margin: 6.8rem auto 2rem;
			}
			@media ${device.tabletMax} {
				margin: 30px 0px 24px;
			}
			@media ${device.laptopMedium} {
				margin: 40px auto;
			}
			.o-header--h2 {
				font-weight: 600;
				font-family: 'Gotham', Arial, Helvetica, sans-serif;

				@media ${device.desktopsignup} {
					font-weight: 700;
					font-family: 'Gotham-Bold', Arial, Helvetica, sans-serif;
				}
			}
		}
		.internal-banner {
			@media ${device.desktopsignup} {
				height: 341px;
			}
			@media ${device.laptopMedium} {
				height: 150px;
			}
		}
		.privacypolicycontent {
			@media ${device.ipadLandscapemin} {
				padding: 0 30px;
			}

			@media ${device.desktopsignup} {
				padding:  0 0 80px;
			}

			@media ${device.tabletMax} {
				margin-top: 0;
				padding-bottom: 0;
			}

			.o-paragraph {
				strong {
					@media ${device.desktopsignup} {
						font-family: "Gotham-Bold", Arial, Helvetica, sans-serif !important;
					}
				}

				@media ${device.desktopsignup} {
					margin-bottom: 3rem;
					font-weight: normal;
				}

				@media ${device.laptopMedium} {
					line-height: 2.2rem;
				}

				.purple-link {
					@media ${device.desktopsignup} {
						color: #0d6efd;
						font-size: 2.2rem;
						line-height: 3.2rem;
						font-family: "Gotham-Book", Arial, Helvetica, sans-serif;
						cursor: pointer;
					}

					&:hover {
						@media ${device.desktopsignup} {
							color: #0a58ca;
						}
					}
				}

				&:nth-child(3) {
					@media ${device.desktopsignup} {
						margin-bottom: 2rem;
					}
				}
				&:nth-child(5) {
					@media ${device.desktopsignup} {
						margin-bottom: 0;
					}
				}
				&:nth-child(6) {
					@media ${device.desktopsignup} {
						margin-bottom: 2.2rem;
					}
				}
				&:nth-child(7) {
					@media ${device.desktopsignup} {
						margin-bottom: 1.5rem;
					}
					strong {
						@media ${device.desktopsignup} {
							font-family: 'Gotham-Book', Arial, Helvetica, sans-serif !important;
							font-weight: normal;
						}
					}
				}
				&:nth-child(9) {
					@media ${device.desktopsignup} {
						margin-bottom: 2rem;
					}
				}
				.gtm-cta {
					@media ${device.laptopMedium} {
						word-break: break-all;
					}
					@media ${device.mobileMedium} {
						white-space: break-spaces;
					}
					@media ${device.ipadLandscapemin} and ${device.miniTopDeskMax} {
						white-space: break-spaces;
					}
					&:first-child {
						@media ${device.mobileMedium} {
							display: block;
						}
						@media ${device.ipadLandscapemin} and ${device.miniTopDeskMax} {
							display: block;
						}
					}
				}
			}
			ul {
				&:nth-child(1) {
					.o-paragraph {
						@media ${device.desktopsignup} {
							font-family: 'Gotham', Arial, Helvetica, sans-serif;
							font-weight: 500;
						}

						strong {
							@media ${device.desktopsignup} {
								font-family: 'Gotham', Arial, Helvetica, sans-serif;
								font-weight: 500;
							}
						}
					}
				}
				.para-medium {
					@media ${device.desktopsignup} {
						font-family: 'Gotham', Arial, Helvetica, sans-serif;
						font-weight: 500;
					}
					&:nth-child(1) {
						@media ${device.desktopsignup} {
							font-size: 2.1rem;
						}
					}
				}
				&.personal-info-para {
					@media ${device.desktopsignup} {
						margin-top: 20px;
					}
				}
			}
			.o-container {
				@media ${device.desktopsignup}{
					padding: 0 0 0 3rem;
				}
				@media ${device.tabletMax} {
					padding: 0px 2.7rem !important;
				}
				@media ${device.laptopMedium} {
					max-width: 718px !important;
					padding: 0 0 0 3rem;
				}
			}
			.o-common-title {
				margin-top: 3rem;
				margin-bottom: 2.4rem;

				@media ${device.desktopsignup} {
					font-family: 'Gotham-Bold', Arial, Helvetica, sans-serif;
					margin-top: 0;
					margin-bottom: 1.5rem;
				}
				@media ${device.ipadLandscapemin} and ${device.miniTopDeskMax} {
					margin: 50px 0px 30px;
				}
				@media ${device.mobileMedium} {
					font-size: 2.7rem;
					line-height: 3.8rem;
				}
			}
			.o-para-fcb-bold {
				font-family: 'Gotham-Book', Arial, Helvetica, sans-serif;

				@media ${device.desktopsignup} {
					margin-bottom: 2rem;
					font-family: 'Gotham-Bold', Arial, Helvetica, sans-serif;
					font-weight: bold;
				}
				@media ${device.laptopMedium} {
					margin-bottom: 20px;
				}
			}
			.mb-15 {
				@media ${device.desktopsignup} {
					margin-bottom: 15px;
				}
			}
			.service-info-para {
				@media ${device.desktopsignup} {
					margin-bottom: 15px;
				}
			}
			.chooses-list {
				@media ${device.desktopsignup} {
					margin-top: 0;
				}
				li {
					&:first-child {
						@media ${device.desktopsignup} {
							margin-bottom: 10px;
						}
					}
				}
			}
			ul {
				ul {
					@media ${device.desktopsignup} {
						margin-top: 0;
					}
					li {
						&:first-child {
							@media ${device.desktopsignup} {
								margin-top: 6px;
								margin-bottom: 15px;
							}
						}
						&:last-child {
							@media ${device.desktopsignup} {
								margin-top: 0;
							}
						}
					}
				}
			}
			.service-info-list {
				@media ${device.desktopsignup} {
					margin-top: 0;
					max-width: 90%;
					word-break: break-all;
				}
				li {
					@media ${device.desktopsignup} {
						margin-bottom: 15px;
					}
				}
			}
			.personnel-info-para {
				@media ${device.desktopsignup} {
					margin-bottom: 20px;
				}
			}
			.above-cookies-para {
				@media ${device.desktopsignup} {
					display: block;
				}
			}
			.personal-info-list {
				@media ${device.desktopsignup} {
					margin-top: 20px;
				}
				ul {
					@media ${device.desktopsignup} {
						margin-top: 30px;
					}
				}
			}
		}
		.footer {
			.footercontainer {
				@media ${device.tabletMax} {
					margin: 95px auto 0;
				}
				@media ${device.ipadLandscapemin} and ${device.miniTopDeskMax} {
					margin: 100px auto 0;
					padding-top: 0;
				}
				footer {
					@media ${device.laptopMedium} {
						padding: 0 2rem;
					}
					@media ${device.laptopPros} {
						padding: 0 10px !important;
					}

					> .row {
						@media ${device.laptopMedium} {
							padding-top: 0;
						}
						@media ${device.ipadLandscapemin} {
							padding-top: 0;
						}
					}
					.footer-list {
						@media ${device.ipadLandscapemin} and ${device.miniTopDeskMax} {
							margin-bottom: 0;
						}
					}
					.o-copyright {
						@media ${device.ipadLandscapemin} and ${device.miniTopDeskMax} {
							margin-top: 0;
						}
					}
				}
			}
			.o-backtotop-btn {
				@media ${device.laptopMedium} {
					top: -100px;
				}
			}
		}
	}
`